




















































































































import { Agendamento } from "@/core/models/operacional";
import { PageBase } from "@/core/models/shared";
import { EnumTipoUsuario } from "@/core/models/shared/Enumerados";
import { AgendamentoService } from "@/core/services/operacional";
import { AlertExcludeQuestion, AlertQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from "@/core/services/shared/AlertService";
import Swal from "sweetalert2";
import { Component, Prop, Watch} from "vue-property-decorator";

@Component
export default class ListaAgendamento extends PageBase {
    search: any = '';
    overlay: boolean = false;
    loading: boolean = false;
    dialogCadastro: boolean = false;
    totalLista: number = 0;
    lista: any[] = [];
    options: any = {
        itemsPerPage: 15
    };
    headers: any[] = [
        { text: '',value:'actions' ,sortable: false },
        { text: '#', value: 'numero', type:'number' },
        { text: 'Cliente', value: 'cliente.nome' },
        { text: 'Usuário', value: 'criacao.usuarioNome' },
        { text: 'Embarcação', value: 'embarcacao.nome' },
        { text: 'Atracação ?', value: 'atracacao', type:'boolean' },
        { text: 'Porto', value: 'porto.nome' },
        { text: 'Entrada', value: 'dataHoraEntrada', type:'date' },
        { text: 'Prático (entrada)', value: 'praticoEntrada', type:'date' },
        { text: 'Prático (saída)', value: 'praticoSaida', type:'date' },
        { text: 'Situação', value: 'situacao.nome' },
        { text: 'Link Documentos', value: 'linkDocumentos' },
        { text: '# RO', value: 'roNumero', type:'number' },
    ];

    item = new Agendamento();
    service = new AgendamentoService();
    justificativa: boolean = false;
    itemJustificativa: Agendamento = new Agendamento();

    tipoUsuario = EnumTipoUsuario;

    situacaoAgendamentoClass(item:any){        
        if(item.situacaoId == 3)
            return 'roReprovada'
        else{
            return ''
        }
    }

    @Watch('options', { deep: true })
    Atualizar(){
        const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
        this.loading = true;
        var filtro;
        if(this.app.clienteId){
            this.headers.splice(2, 1);
            filtro = `clienteId eq ${this.app.clienteId}`;
        }
        this.service.Listar(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, filtro, "Cliente, Porto, Situacao, Embarcacao").then(
            res => {
                this.lista = res.data.items;
                this.totalLista = res.data.count;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.loading = false));
    }

    AbrirDialogCadastro(item?: Agendamento){
        if(item){
            this.overlay = true;
            this.service.ObterPorId(item.id, "Cliente, Porto, Embarcacao, Equipamentos.Equipamento, Documentos.Documento").then(
                res=>{
                    this.item = res.data;
                    this.dialogCadastro = true;
                },
                err => AlertSimpleErr("Aviso!", err)
            ).finally(() => {
                this.overlay = false;
            })
        }
        else{
            this.item = new Agendamento();
            this.item.empresaId = this.app.empresaId;
            this.dialogCadastro = true;
        }
    }

    Excluir(item: Agendamento){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                await context.service.Excluir(item.id).then(
                    res => {
                        if(res.status == 200){
                            return resolve(res.data);
                        }
                    },
                    err => {
                        AlertSimpleErr("Aviso", err);
                    }
                ).finally(() => {
                    context.Atualizar();
                })
            });
        }
        AlertExcludeQuestion(excluir);
    }

    Cancelar(item: Agendamento){
        Swal.fire({
            title: 'Atenção!',
            html: 'Tem certeza que deseja CANCELAR o registro atual? <br/> Essa operação não poderá ser desfeita. <br/> Justifique o motivo do cancelamento.',
            icon: 'question',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            showCloseButton: true,
            showLoaderOnConfirm: true,
            preConfirm: (justificativa) => {
                return this.service.Cancelar(item.id, justificativa).then(
                    res => {
                        if (res.status == 200){
                            return res.data;
                        }
                    },
                    err => AlertSimpleErr("Aviso!", err)
                )
            },
            // @ts-ignore: Unreachable code error
            allowOutsideClick: () => !this.$swal.isLoading()
        })
        .then((result) => {
            if(result.value) {
                AlertSimple("Aviso!", result.value, "success");
                this.Atualizar();
            }
        })
    }

    Reabrir(item: Agendamento){
        const context = this;
        const enviar = function () {
            return new Promise( async function (resolve, reject){
                context.service.Reabrir(item.id).then(
                    res => {
                        AlertSimpleRes("Aviso!", res);
                        context.Atualizar();
                    },
                    err => AlertSimpleErr("Aviso!", err)
                )
            });
        }
        AlertQuestion("Atenção!", "Tem certeza que deseja REABRIR o registro atual?", enviar);
    }

    Justificativa(item: Agendamento){
        this.justificativa = true;
        this.itemJustificativa = item;
    }
}
